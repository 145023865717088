import React, { Component } from 'react';
import mongoApi from '../../services/mongoApi';

import { Link } from 'react-router-dom';

export default class Main extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      nome: '',  //*
      email: '', //*
      cpf: '',
      cel: '',   //*
      sexo: 'Feminino',   //*
      nascimento: '',
      key: '',
      valid: 0,
      tipoChave: 'Colaborador',
      termoAceite: ''
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  async componentDidMount()
  {
    const { id } = this.props.match.params;

    const response = await mongoApi.get(`/key/${id}`);
    if(response.status !== 200 || !response.data || !response.data.ativo)
    {
      this.setState({ valid: 2 });
      return;
    }

    this.setState({ valid: 1, cpf: response.data.cpf, nome: response.data.nome, key: response.data._id.toString(), email: response.data.email });
  }

  handleInputChange(event)
  {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  nextPage = pageChange =>
    {
      const { nome, email, cel, sexo, cpf, nascimento, key, termoAceite } = this.state;

      if(nome === '')
      {
        alert('O campo Nome é obrigatório.');
        pageChange.preventDefault(); // Faz com que o roteador nao mude de página
        return;
      }

      if(email === '')
      {
        alert('O campo E-mail é obrigatório.');
        pageChange.preventDefault();
        return;
      }

      if(cel === '')
      {
        alert('O campo Celular é obrigatório.');
        pageChange.preventDefault();
        return;
      }

      if (cel.length < 15)
      {
        alert('O campo Celular não foi preenchido corretamente.');
        pageChange.preventDefault();
        return;
      }

      if(nascimento === '')
      {
        alert('O campo Celular é obrigatório.');
        pageChange.preventDefault();
        return;
      }

      if (nascimento.length < 10)
      {
        alert('O campo Data de Nascimento não foi preenchido corretamente.');
        pageChange.preventDefault();
        return;
      }

      if(termoAceite === '')
      {
        alert('A declaração de aceite de compartilhamentoo e armazenamento dos seus dados e perfil é Obrigatória.');
        pageChange.preventDefault();
        return;
      }


      sessionStorage.setItem('nome', nome);
      sessionStorage.setItem('email', email);
      sessionStorage.setItem('cel', cel);
      sessionStorage.setItem('sexo', sexo);
      sessionStorage.setItem('termoAceite', termoAceite);
      sessionStorage.setItem('cpf', cpf);
      var aux = nascimento.split('/');
      var nascimentoFormat = aux[1] + '/' + aux[0] + '/' + aux[2];
      sessionStorage.setItem('nascimento', nascimentoFormat);
      sessionStorage.setItem('key', key);
    }

  renderCultureSurvey()
  {
    let { tipoChave } = this.state;

    if(tipoChave === 'Candidato')
      return(
        <div>
          <hr/>

          <div className="row mb-1 mt-4">
            <div className="col-md-8">
              <p className="lead-2 ssp">Agora, pense, em que <b>tipo de ambiente</b> você se sente mais confortável?</p>
            </div>
          </div>

          <div className="form-row" onChange={this.handleInputChange}>
            <div className="col-md-6 form-check-coluna-esquerda">
              <div class="form-check">
                <input 
                  name="flex"
                  type="radio"
                  value="flexível"
                  className="form-check-input" 
                  id="flex1" />
                <label class="form-check-label labelCultura" for="flex1">
                  Um ambiente com menos foco em hierarquia, que busca continuamente construir “o novo” para conquistar o mercado.
                </label>
              </div>
              <h3 className="ou">ou</h3>
              <div class="form-check">
                <input 
                  name="flex"
                  type="radio"
                  value="estável"
                  className="form-check-input" 
                  id="flex2" />
                <label class="form-check-label labelCultura" for="flex2">
                  Um ambiente que valoriza a hierarquia, e que se coloca no mercado com produtos estabelecidos e, muitas vezes, fortes.
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-check">
                  <input
                    name="depend"
                    type="radio"
                    value="interdependência"
                    className="form-check-input"
                    id="depend1"
                    /> 
                    <label class="form-check-label labelCultura" for="depend1">
                      Um ambiente de mais consenso, orientado a relacionamentos (empatia), onde as pessoas prioritariamente buscam o melhor desempenho como grupo.
                    </label>
                </div>
                <h3 className="ou">ou</h3>
                <div class="form-check">
                  <input
                    name="depend"
                    type="radio"
                    value="independência"
                    className="form-check-input"
                    id="depend2"
                    /> 
                    <label class="form-check-label labelCultura" for="depend2">
                      Um ambiente com mais meritocracia, orientado a números (faturamento, prazos), onde o que prevalece  é  o melhor desempenho individual.
                    </label>
                </div>
            </div>
          </div>
         </div>
         
      );
    
    return;
  }

  render() {
    if(this.state.valid === 0)
      return(
      <div>
        
        

        <div className="wrapper" id="single-wrapper">
        <div className="p-0" tabIndex="-1">


          <section className="mt-5 mb-5">
				    <div className="container position-content-react">
					    <div className="row">

                <div className="col-md-3">
                  <div className="menu-lateral-relatorio sticky-top pt-3 text-right">
                    <div className="nav flex-column mt-4" role="tablist">
                      <a className="nav-link active" role="tab">Carregando</a>
                    </div>
                  </div>
                </div>

                <div className="col-md-9 col-content-style">
                  <div className="row">
                    <div className="col-md-9">
                      <p className="lead-2 ssp"><b>Carregando...</b></p>
                      <p className="lead-2 ssp"></p>
                    </div>
                  </div>
                  
                </div>

              </div>
            </div>
          </section>
        </div>
      </div>


      </div>
      );

    if(this.state.valid === 2)
      return(
      <div>
        
        

        <div className="wrapper" id="single-wrapper">
        <div className="p-0" tabIndex="-1">


          <section className="mt-5 mb-5">
				    <div className="container position-content-react">
					    <div className="row">

                <div className="col-md-3">
                  <div className="menu-lateral-relatorio sticky-top pt-3 text-right">
                    <div className="nav flex-column mt-4" role="tablist">
                      <a className="nav-link active" role="tab">Link Inválido</a>
                    </div>
                  </div>
                </div>

                <div className="col-md-9 col-content-style">
                  <div className="row">
                    <div className="col-md-10">
                      <p className="lead-2 ssp"><b>Ops!</b></p>
                      <p className="lead-2 ssp">Esse link que você acessou está incorreto ou já foi utilizado. Entre em contato com seu gestor.</p>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </section>
        </div>
      </div>


      </div>
      );

    return (

      <div className='register-form position-content-react'>
        <div className="wrapper" id="single-wrapper">
          <div className="p-0" tabIndex="-1">
            <section className="mt-5 mb-5">
              <div className="container">
                <div className="row">

                  <div className="col-md-3">
                    <div className="menu-lateral-relatorio sticky-top pt-3 text-right">
                      <div className="nav flex-column mt-4" role="tablist">
                        <a className="nav-link active">Dados pessoais</a>
                        <a className="nav-link" role="tab">Primeira pergunta</a>
                        <a className="nav-link" role="tab">Segunda pergunta</a>
                        <a className="nav-link" role="tab">Confirmação</a>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-9 col-content-style">
                    <div className="row mb-4">
                      <div className="col-md-9">
                        <p className="lead-2 ssp mb-2"><b>Olá,</b> {this.state.nome.split(' ')[0]}.</p><p className="lead-2 ssp">Seja bem-vindo à ferramenta de mapeamento de perfil comportamental mais moderna do Brasil.</p>
                      </div>
                      <div className="col-md-10">
                        <p className="d-none">É a melhor, mais rápida e mais acessível ferramenta para ajudar as pessoas a se desenvolverem nos âmbitos pessoal e profissional. Depois de estudar 8 metodologias globais, dentre elas o DISC, o algoritmo Sólides foi validado por USP e UFMG com exatidão superior a 97,9%.</p>
                        <p>Reserve 10 minutinhos para o seu mapeamento, sem interrupções ou ajuda, e, se possível, faça no computador (a visualização das palavras fica melhor).</p>
                      </div>
                    </div>

                    <hr/>

                    <div className="row mb-1 mt-4">
                      <div className="col-md-6">
                        <p className="lead-2 ssp"><b>Antes de começar,</b> confirme os seus dados, por favor.</p>
                      </div>
                    </div>

                    <form className="main-form color-neutro-azul">
                      <div className="form-row">
                        <div className="col-md-6">
                          <label>
                            Nome*:
                            <input
                                name="nome"
                                className="form-control"
                                placeholder="Informe seu nome completo"
                                type="text"
                                value={this.state.nome}
                                disabled
                            />
                          </label>
                        </div>
                        <div className="col-md-6">
                        <label>
                          E-mail*:
                          <input
                              name="email"
                              className="form-control"
                              placeholder="E-mail para contato"
                              type="text"
                              value={this.state.email}
                              disabled />
                        </label>
                        </div>
                        <div className="col-md-6">
                          <label>
                            Celular*:
                            <input
                              id="main-celular"
                              placeholder="(00) 00000-0000"
                              className="form-control"
                              name="cel"
                              type="text"
                              value={this.state.cel}
                              onChange={this.handleInputChange} />
                          </label>
                        </div>
                        <div className="col-md-3">
                          <label>
                            Data de Nascimento*:
                            <input
                              id="main-data-nascimento"
                              className="form-control"
                              name="nascimento"
                              type="text"
                              value={this.state.nascimento}
                              onChange={this.handleInputChange} />
                          </label>
                        </div>
                        <div className="col-md-3">
                          <label>
                            Sexo*:
                            <select
                              name="sexo"
                              className="form-control"
                              value={this.state.sexo}
                              onChange={this.handleInputChange}>
                              <option value='Masculino'>Masculino</option>
                              <option value='Feminino'>Feminino</option>
                            </select>
                          </label>
                        </div>
                      </div>

                      <div className="form-row mt-3">
                        <div className="col-md-12">
                          <p className="mb-1 labelAceite text-uppercase w-600">Antes de iniciar seu mapeamento,</p>
                          <div class="form-check">
                            <input
                              name="termoAceite"
                              id="termoAceite"
                              type="radio"
                              className="form-check-input"
                              defaultChecked={this.state.termoAceite}
                              onChange={this.handleInputChange} 
                              /> 
                            <label class="form-check-label labelAceite" for="termoAceite">
                              Ao marcar esta opção, você declara que concorda com o compartilhamento e armazenamento dos seus dados pessoais e de perfil comportamental com a Persora.
                            </label>
                          </div>
                        </div>
                      </div>

                    </form>


                    <div className='actions'>

                      <div className="row mt-3">
                        
                        <div className="col-md-12">
                        <hr className="mb-4"/>
                          <p>Agora, vamos para o mapeamento do perfil. São apenas 2 perguntas!</p>
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-md-12 text-right">
                          <Link to='/Survey#' onClick={this.nextPage}>
                            <span className='btn btn-persora1 text-uppercase small-com-ls w-700'>
                              Vamos lá!
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </section>
          </div>
        </div>

      </div>/* register-form end */
    );
  }
}