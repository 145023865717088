import React from 'react';

import { HashRouter, Switch, Route } from 'react-router-dom';

import Ghost from './pages/Ghost';
import Main from './pages/Main';
import Survey from './pages/Survey';
import SurveyEnding from './pages/SurveyEnding';

const Routes = () => (
  <HashRouter>
    <Switch>
      <Route exact path='/' component={Ghost}/>
      <Route path='/id/:id' component={Main}/>
      <Route path='/Survey' component={Survey}/>
      <Route path='/SurveyEnding' component={SurveyEnding}/>

    </Switch>
  </HashRouter>
);



export default Routes;
