import React, { Component } from 'react';

export default class Ghost extends Component
{
  render()
  {
    return(
      <div>
      <div className="wrapper" id="single-wrapper">
      <div className="p-0" tabIndex="-1">


        <section className="mt-5 mb-5">
          <div className="container position-content-react">
            <div className="row">

              <div className="col-md-3">
                <div className="menu-lateral-relatorio sticky-top pt-3 text-right">
                  <div className="nav flex-column mt-4" role="tablist">
                    <a className="nav-link active" role="tab">Link Inválido</a>
                  </div>
                </div>
              </div>

              <div className="col-md-9 col-content-style">
                <div className="row">
                  <div className="col-md-10">
                    <p className="lead-2 ssp"><b>Ops!</b></p>
                    <p className="lead-2 ssp">Esse link que você acessou não é válido para fazer o mapeamento de perfil comportamental.</p>
                  </div>
                </div>
                
              </div>

            </div>
          </div>
        </section>
      </div>
    </div>
    </div>
    );
  }
}
