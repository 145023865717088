import React from 'react';

import './styles.css';


const Header = () => (
        <header id='main-header' className=''>
          <div className="container" id="top">
            <div className="row">
              <div className="col-md-12">
                <img src="https://www.persora.com.br/mapeamento/images/logo-persora.svg" alt="Persora" width="170px"></img>
              </div>
            </div>
          </div>
        </header>
);


export default Header;
