import React from "react";

const Checkbox = ({ preText, label, isSelected, onCheckboxChange }) => (
  <div className="pretty p-icon p-curve p-rotate pretty-persora-check">
    <input
      type="checkbox"
      name={label}
      checked={isSelected}
      onChange={onCheckboxChange}
      className="form-check-input"
    />
    <div className="state p-info-o">
      <i className="icon mdi mdi-check"><i className="fas fa-check"></i></i>
      <label htmlFor={label}>
        <span className="pre-text">{preText}</span>
        {' ' + label}
      </label>
    </div>
  </div>
);

export default Checkbox;