import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Checkbox from '../../components/Checkbox';

import './styles.css';

const OPTIONS = [
  'Contagiante',
  'Audacioso (ousado)',
  'Indeciso',
  'Equilibrado',
  'Inseguro',
  'Líder',
  'Ingênuo',
  'Leal',
  'Dedicado',
  'Otimista',
  'Eficiente',
  'Habilidoso',
  'Corajoso',
  'Comunicativo',
  'Decidido',
  'Medroso',
  'Ativo',
  'Intolerante',
  'Pretensioso',
  'Persistente',
  'Barulhento',
  'Animado',
  'Prático',
  'Minucioso',
  'Discreto',
  'Desconfiado',
  'Auto-Disciplinado',
  'Entusiasta',
  'Resoluto (decidido)',
  'Sensível',
  'Crítico',
  'Exigente',
  'Influenciador',
  'Empolgante',
  'Desmotivado',
  'Insensível',
  'Anti-social',
  'Estimulante',
  'Inflexível',
  'Vingativo',
  'Extrovertido',
  'Orgulhoso',
  'Teórico',
  'Exagerado',
  'Firme',
  'Modesto',
  'Alegre',
  'Racional',
  'Rotineiro',
  'Calculista',
  'Bem-Quisto',
  'Destacado',
  'Pessimista',
  'Metódico',
  'Calmo',
  'Tranquilo',
  'Espalhafatoso',
  'Vaidoso',
  'Bem-humorado',
  'Simpático',
  'Impaciente',
  'Bom Companheiro',
  'Arrogante',
  'Egocêntrico',
  'Conservador',
  'Desorganizado',
  'Frio',
  'Cumpridor',
  'Popular',
  'Paciente',
  'Sentimental',
  'Depressivo',
  'Reservado',
  'Enérgico',
  'Perfeccionista',
  'Sincero',
  'Idealista',
  'Indisciplinado',
  'Auto-Suficiente',
  'Exuberante',
  'Introvertido',
  'Independente',
  'Egoísta',
  'Temeroso',
  'Procrastinador',
  'Sarcástico',
  'Compreensivo'
];

export default class Survey extends Component {

  state = {
    checkboxes: OPTIONS.reduce(
      (options, option) => ({
        ...options,
        [option]: false
      }),
      {}
    ),
    pagina: 1,
    count: 0
  };

  now() {

    var date = new Date();
    var aaaa = date.getUTCFullYear();
    var gg = date.getUTCDate();
    var mm = (date.getUTCMonth() + 1);

    if (gg < 10)
      gg = "0" + gg;

    if (mm < 10)
      mm = "0" + mm;

    var cur_day = aaaa + "-" + mm + "-" + gg;

    var hours = date.getUTCHours() - 3;
    var minutes = date.getUTCMinutes() - 3 ;
    var seconds = date.getUTCSeconds() - 3;

    if (hours < 10)
      hours = "0" + hours;

    if (minutes < 10)
      minutes = "0" + minutes;

    if (seconds < 10)
      seconds = "0" + seconds;

    return cur_day + " " + hours + ":" + minutes + ":" + seconds;

  }


  componentDidMount()
  {
    window.scrollTo(0,0);
    sessionStorage.setItem('horaInicio',this.now());
  }

  formatString(str)
  {
    var s = str;

    // Adaptaçao de Resoluto para C82 (api solides)
    if(s.includes('Resoluto'))
      return 'C82' + this.state.pagina;

    if(s.includes('-')) // Tirar o hífen
    {
      s = s.split('-');
      s = s[0] + s[1];
    }

    if(s.includes(' '))
    {
      if(s.includes('(')) // Ignorar as chaves, caso existam
      {
        s = s.split(' ');
        s = s[0];
      }

      else // Tirar o espaço e juntar
      {
        s = s.split(' ');
        s = s[0] + s[1];
      }
    }

    s = s.normalize('NFD').replace(/[\u0300-\u036f]/g, ""); //Tirar os acentos

    //Retorna a string em lowercase com 1 ou 2 no final (dependendo da página)
    return s.toLowerCase() + this.state.pagina;
  }

  handleCheckboxChange = changeEvent => {
    const { name, checked } = changeEvent.target;

    this.setState(prevState => ({
      checkboxes: {
        ...prevState.checkboxes,
        [name]: !prevState.checkboxes[name]
      }
    }));

    if(checked)
    {
      const aux = this.state.count + 1;
      this.setState({ count : aux });
    }

    else
    {
      const aux = this.state.count - 1;
      this.setState({ count : aux });
    }
  };

  selectAllCheckboxes = isSelected => {
    Object.keys(this.state.checkboxes).forEach(checkbox => {
      this.setState(prevState => ({
        checkboxes: {
          ...prevState.checkboxes,
          [checkbox]: isSelected
        }
      }));
    });
  };

  deselectAll = () => this.selectAllCheckboxes(false);

  handleFormSubmit = formSubmitEvent => {
    if(this.state.pagina === 1)
    {
      formSubmitEvent.preventDefault();
    }

    var adjs = []; // Array de adjetivos

    if(this.state.count <= 4)
    {
      alert('Por favor, marque mais de 4 adjetivos no formulário.');
      if(this.state.pagina === 2)
      {
        formSubmitEvent.preventDefault();
      }
      return;
    }

    Object.keys(this.state.checkboxes)
      .filter(checkbox => this.state.checkboxes[checkbox])
      .forEach(checkbox => {
        adjs.push(this.formatString(checkbox)); // Coloca os adjetivos marcados no array
      });

    if(this.state.pagina === 1)
    {
      this.setState( { pagina: this.state.pagina + 1 } );
      sessionStorage.setItem('ad1', JSON.stringify(adjs)); // Armazena os adjetivos no sessionStorage
      window.scrollTo(0,0);
      this.deselectAll();
      this.setState( {count : 0} );
    }

    else
    {
      sessionStorage.setItem('ad2', JSON.stringify(adjs)); // Armazena os adjetivos(2) no sessionStorage
      sessionStorage.setItem('horaFim',this.now());
    }
  };

  createCheckbox = option => {
    if(this.state.pagina === 2)
      return(
        <Checkbox
          label={option}
          preText='(preciso ser)'
          isSelected={this.state.checkboxes[option]}
          onCheckboxChange={this.handleCheckboxChange}
          key={option}
        />
      );

    return(
      <Checkbox
        label={option}
        preText='(eu sou)'
        isSelected={this.state.checkboxes[option]}
        onCheckboxChange={this.handleCheckboxChange}
        key={option}
      />
    );
  };

  createCheckboxes = () => OPTIONS.map(this.createCheckbox);

  createTextBoxes = () => {
    if(this.state.pagina === 1)
    {
      return(
        <div className='instructions'>
            <div className="row">
              <div className="col-md-8">
                <p className="lead-2 ssp"><b>Parte 1 de 2</b></p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-11">
                <ul className="ul-orientaçoes-mapeamento">
                  <li>O objetivo do mapeamento é você se conhecer melhor e isso só será possível se a sua autoavaliação for verdadeiramente sincera. Marque todas as palavras que realmente te representam, mesmo que pareçam negativas. Não marque o que você gostaria de ser, mas sim aquilo que você realmente é.</li>
                  <li>Se não souber o significado de uma palavra, desconsidere-a. Ah, ninguém saberá quais palavras você marcou.</li>
                </ul>
              </div>
            </div>
            <hr/>
            <div className="row mt-4 mb-3">
              <div className="col-md-9">
                <p className="lead-2 ssp"><b>Vamos começar!</b> Quais dessas palavras realmente te representam?</p>
              </div>
            </div>
        </div> /*finaliza instrucoes 1 de 2*/
      );
    }

    return(
      <div className='instructions'>
        <div className="row">
          <div className="col-md-8">
            <p className="lead-2 ssp"><b>Parte 2 de 2</b></p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-11">
            <ul className="ul-orientaçoes-mapeamento">
              <li>Agora você irá marcar as palavras que descrevem o que as pessoas à sua volta regularmente te cobram, mesmo que você não concorde.</li>
              <li>Se não souber o significado de uma palavra, desconsidere-a. Ah, ninguém saberá quais palavras você marcou.</li>
            </ul>
          </div>
        </div>
        <hr/>
        <div className="row mt-4 mb-3">
          <div className="col-md-9">
            <p className="lead-2 ssp"><b>Vamos lá!</b> Quais dessas palavras descrevem como as pessoas à sua volta dizem que você deve ou precisa ser para ter um desempenho melhor?</p>
          </div>
        </div>
      </div>
    );
  }

  createSubmitButton()
  {
    if(this.state.pagina === 1)
    {
      return(
        <button type='submit' className='btn btn-persora1 text-uppercase small-com-ls w-700'>
          Avançar
        </button>
      );
    }

    return(
      <Link to='/SurveyEnding' onClick={this.handleFormSubmit}>
        <span className='btn btn-persora1 text-uppercase small-com-ls w-700'>
          Concluir
        </span>
      </Link>
    );
  }

  createBar()
  {
    if(this.state.pagina === 1)
      return(
        <div className="col-md-3">
        <div className="menu-lateral-relatorio sticky-top pt-3 text-right">
          <div className="nav flex-column mt-4" role="tablist">
            <a className="nav-link passed">Dados pessoais</a>
            <a className="nav-link active" role="tab">Primeira pergunta</a>
            <a className="nav-link" role="tab">Segunda pergunta</a>
            <a className="nav-link" role="tab">Confirmação</a>
          </div>
        </div>
      </div>
      );

      return(
        <div className="col-md-3">
        <div className="menu-lateral-relatorio sticky-top pt-3 text-right">
          <div className="nav flex-column mt-4" role="tablist">
            <a className="nav-link passed">Dados pessoais</a>
            <a className="nav-link passed" role="tab">Primeira pergunta</a>
            <a className="nav-link active" role="tab">Segunda pergunta</a>
            <a className="nav-link" role="tab">Confirmação</a>
          </div>
        </div>
      </div>
      );
  }

  render() {
    return (
      <div className='container position-content-react'>
        <div className='row'>


        <div className="wrapper" id="single-wrapper">
            <div className="p-0" tabIndex="-1">
              <section className="mt-5 mb-5">
                <div className="container">
                  <div className="row">
                    {this.createBar()}


                    <div className="col-md-9 col-content-style">
                      <div className="row">
                        <div className="col-md-12">
                          
                            {this.createTextBoxes()}

                            

                            <form onSubmit={this.handleFormSubmit}>
                              <div className='col-sm-12 tres-colunas'>
                                {this.createCheckboxes()}
                              </div>
                              <div className='col-md-12 text-right'>
                                <div className='form-group mt-5'>
                                  {this.createSubmitButton()}
                                </div>
                              </div>
                            </form>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>



        </div>
      </div>
    );
  }
}
