import React, { Component } from 'react';
import mongoApi from '../../services/mongoApi';

export default class SurveyEnding extends Component
{
 
  constructor(props){
    super(props);
    this.state = {
      valid: 0
    };
  }

  async createData()
  {
    //Recuperar adjetivos
    let raw1 = sessionStorage.getItem('ad1');
    let raw2 = sessionStorage.getItem('ad2');

    let data1 = JSON.parse(raw1);
    let data2 = JSON.parse(raw2);

    let adjetivos = data1.concat(data2); //Array com todos os adjetivos


    //Recuperar cpf
    let cpf = sessionStorage.getItem('cpf');
    //Recuperar tipo da chave
    let tipo = sessionStorage.getItem('tipoChave');
    //Recuperar respostas do Questionário de Cultura
    let cultura = JSON.parse(sessionStorage.getItem('cultura'));

    const email_sent = (tipo === 'Candidato');

    var solidesObject;

    if(cpf === '')
    {
      solidesObject = {
        idioma: 'Portugues',
        devolver_resultado: 1,
        tipo_relatorio: 2,
        horainicio: sessionStorage.getItem('horaInicio'),
        horafinal: sessionStorage.getItem('horaFim'),
        telefone: sessionStorage.getItem('cel'),
        email: sessionStorage.getItem('email'),
        nome: sessionStorage.getItem('nome'),
        sexo: sessionStorage.getItem('sexo'),
        cpf: cpf,
        nascimento: sessionStorage.getItem('nascimento'),
        key: sessionStorage.getItem('key'),
        email_sent,
        tipo,
        cultura
      };
    }

    else
    {
      solidesObject = {
        idioma: 'Portugues',
        devolver_resultado: 1,
        tipo_relatorio: 2,
        horainicio: sessionStorage.getItem('horaInicio'),
        horafinal: sessionStorage.getItem('horaFim'),
        telefone: sessionStorage.getItem('cel'),
        email: sessionStorage.getItem('email'),
        nome: sessionStorage.getItem('nome'),
        sexo: sessionStorage.getItem('sexo'),
        cpf: cpf,
        nascimento: sessionStorage.getItem('nascimento'),
        key: sessionStorage.getItem('key'),
        email_sent,
        tipo,
        cultura
      };
    }


    let mongoObject = { ...{ adjetivos: adjetivos }, ...solidesObject };

    // Salvando no mongoDB
    const mongoResponse = await mongoApi.post('/survey', mongoObject);

    if(mongoResponse.status !== 200){
      this.setState({ valid: 2}); 
      }

    else{
      this.setState({ valid: 1}); 
    }

    sessionStorage.clear();
  }

  async componentDidMount()
  {
    if(sessionStorage !== 0)
      this.createData();
  }

  handleInputChange(event)
  {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }


  render()
  {
    if(this.state.valid === 0)
      return(
      <div>
        
        

        <div className="wrapper" id="single-wrapper">
        <div className="p-0" tabIndex="-1">


          <section className="mt-5 mb-5">
				    <div className="container position-content-react">
					    <div className="row">

                <div className="col-md-3">
                  <div className="menu-lateral-relatorio sticky-top pt-3 text-right">
                    <div className="nav flex-column mt-4" role="tablist">
                      <a className="nav-link active" role="tab">Carregando</a>
                    </div>
                  </div>
                </div>

                <div className="col-md-9 col-content-style">
                  <div className="row">
                    <div className="col-md-9">
                      <p className="lead-2 ssp"><b>Carregando...</b></p>
                      <p className="lead-2 ssp"></p>
                    </div>
                  </div>
                  
                </div>

              </div>
            </div>
          </section>
        </div>
      </div>


      </div>
      );

    else if(this.state.valid === 2)
    {
      sessionStorage.setItem('tipoChave',true);

      return(
        <div className='thank-you'>
          <div className="wrapper" id="single-wrapper">
            <div className="p-0" tabIndex="-1">
              <section className="mt-5 mb-5">
                <div className="container position-content-react">
                  <div className="row">

                    <div className="col-md-3">
                      <div className="menu-lateral-relatorio sticky-top pt-3 text-right">
                        <div className="nav flex-column mt-4" role="tablist">
                          <a className="nav-link passed">Dados pessoais</a>
                          <a className="nav-link passed" role="tab">Primeira pergunta</a>
                          <a className="nav-link passed" role="tab">Segunda pergunta</a>
                          <a className="nav-link active" role="tab">Confirmação</a>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-9 col-content-style">
                      <div className="row">
                        <div className="col-md-9">
                          <p className="lead-2 ssp"><b>Ops! Algo de errado aconteceu.</b></p>
                          </div>
                        <div className="col-md-10">
                          <p className="lead ssp">Não se preocupe, vamos começar de novo. Reinicie o seu mapeamento.</p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      );
    }

    let tipo = sessionStorage.getItem('tipoChave');

    if(tipo==='Candidato')
      return(
        <div className='thank-you'>
          <div className="wrapper" id="single-wrapper">
            <div className="p-0" tabIndex="-1">
              <section className="mt-5 mb-5">
                <div className="container position-content-react">
                  <div className="row">

                    <div className="col-md-3">
                      <div className="menu-lateral-relatorio sticky-top pt-3 text-right">
                        <div className="nav flex-column mt-4" role="tablist">
                          <a className="nav-link passed">Dados pessoais</a>
                          <a className="nav-link passed" role="tab">Primeira pergunta</a>
                          <a className="nav-link passed" role="tab">Segunda pergunta</a>
                          <a className="nav-link active" role="tab">Confirmação</a>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-9 col-content-style">
                      <div className="row">
                        <div className="col-md-9">
                          <p className="lead-2 ssp"><b>Parabéns,</b> preenchimento concluído!</p>
                          </div>
                        <div className="col-md-10">
                          <p className="lead ssp">Logo você receberá por e-mail as instruções para acessar seu mapeamento. Não deixe de verificar a sua caixa de SPAM.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      );

    else
    {
      return(
        <div className='thank-you'>
          <div className="wrapper" id="single-wrapper">
            <div className="p-0" tabIndex="-1">
              <section className="mt-5 mb-5">
                <div className="container position-content-react">
                  <div className="row">

                    <div className="col-md-3">
                      <div className="menu-lateral-relatorio sticky-top pt-3 text-right">
                        <div className="nav flex-column mt-4" role="tablist">
                          <a className="nav-link passed">Dados pessoais</a>
                          <a className="nav-link passed" role="tab">Primeira pergunta</a>
                          <a className="nav-link passed" role="tab">Segunda pergunta</a>
                          <a className="nav-link active" role="tab">Confirmação</a>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-9 col-content-style">
                      <div className="row">
                        <div className="col-md-9">
                          <p className="lead-2 ssp"><b>Parabéns,</b> preenchimento concluído!</p>
                          </div>
                        <div className="col-md-10">
                          <p className="lead ssp">Logo você receberá por e-mail as instruções para acessar seu mapeamento. Não deixe de verificar a sua caixa de SPAM.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
       );
    }
  }
}